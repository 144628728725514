import styled from "@emotion/styled"
import * as React from "react"
import { useTranslation } from "react-i18next"
import tw from "twin.macro"
import { CustomLink } from "../components/Common/Button"
import { Description, Heading } from "../components/Common/Headings"
import DynamicImage from "../components/Common/DynamicImage"
import Seo from "../components/SEO"

const Container = styled.div`
  ${tw`py-32 max-w-screen-xl mx-auto mx-auto sm:py-40`}
`
const ImgWrapper = tw.div`m-auto w-full sm:w-4/5`
const ContentWrapper = tw.div`p-8 sm:pl-20`
const Link = styled(CustomLink)`
  ${tw`ml-0!`}
`
const ThankYouPage = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Seo title={t("THANK_YOU_HEADING")} />
      <div tw="sm:flex">
        <ImgWrapper>
          <DynamicImage filename={"thank-you.png"} />
        </ImgWrapper>
        <ContentWrapper>
          <Heading tw="text-left">{t("THANK_YOU_HEADING")}</Heading>
          <Description tw="text-left">
            {t("THANK_YOU_CONTENT")}
          </Description>
          <Description  tw="text-left mb-6">
          <div tw="mt-6 text-sm">
            <div tw="font-bold">E-mail:</div>
            <div>wesight@huawei.com</div>
          </div>
          </Description>
          <Link to="">{t("404_BUTTON_TEXT")}</Link>
        </ContentWrapper>
      </div>
    </Container>
  )
}

export default ThankYouPage
