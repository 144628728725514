import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const DynamicImage = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1400,, quality: 100,maxHeight:1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }

      const fluid = image.node.childImageSharp.fluid
      return <Img fluid={fluid} alt={props.filename} />
    }}
  />
)

export default DynamicImage
